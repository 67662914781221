
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { ServerType } from "prometheus-synced-ui";
import { DegreaserDetails, DegreaserItem, LITE_FEATURE } from "@/domain";

import { IDegreaserService, IEquipmentBrandService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class DegreasersTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get degreaserService() {
    return this.container.resolve<IDegreaserService>(S.DEGREASER_SERVICE);
  }

  get equipmentBrandService() {
    return this.container.resolve<IEquipmentBrandService>(
      S.EQUIPMENT_BRAND_SERVICE
    );
  }

  get existingSerialNumbers() {
    return _.map(this.degreasers, (s) => s.serialNumber);
  }

  get headers() {
    return [
      {
        text: "Numéro de série",
        align: "center",
        sortable: true,
        value: "serialNumber",
      },
      {
        text: "Type",
        align: "center",
        sortable: true,
        value: "equipmentType",
      },
      {
        text: "Marque",
        align: "center",
        sortable: true,
        value: "equipmentBrand.name",
      },
      {
        text: "Numéro de PV",
        align: "center",
        sortable: true,
        value: "pvNumber",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  get createComponent() {
    return () =>
      import("@/components/Core/Create/Equipments/DegreaserCreate.vue");
  }

  get updateComponent() {
    return () =>
      import("@/components/Core/Update/Equipments/DegreaserUpdate.vue");
  }

  degreasers: DegreaserItem[] = [];
  loading: boolean = false;
  showDisabledDegreasers: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.degreasers = await this.degreaserService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(degreaser: DegreaserDetails) {
    this.degreasers.push(DegreaserItem.fromDetails(degreaser));
  }

  update(degreaser: DegreaserDetails) {
    const index = _.findIndex(this.degreasers, (d) => d.id == degreaser.id);
    if (index != -1) {
      this.degreasers.splice(index, 1, DegreaserItem.fromDetails(degreaser));
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const degreaser = _.find(this.degreasers, (d) => d.id == id)!;
      const result = await this.degreaserService.update(id, {
        serialNumber: degreaser.serialNumber,
        equipmentType: degreaser.equipmentType,
        equipmentBrandId: degreaser.equipmentBrand.id,
        pvNumber: degreaser.pvNumber,
        disabled: !degreaser.disabled,
      });

      this.update(result);
    } finally {
      this.loading = false;
    }
  }
}
